<template>
  <div>
    <div class="icon-nav ">
      <ul class="d-flex align-items-center  ">

        <li class="iconphone ">
          <a href="tel:+32081830202" aria-label="Telephone">
            <i class="fa fa-phone text-white" aria-hidden="true"></i>
          </a>
          <v-tooltip attach="ul" activator="parent" location="bottom" role="tooltip" aria-label="Telephone">
            {{ $t("call_us") }}: {{ "+32 (0) 818-302-02" }}
          </v-tooltip>
        </li>
        <!-- Tutorial Button -->
        <div v-if="false">
          <li class="icon-bulb" v-if="showTourBtn">
            <button @click="emitOpenTour">
              <i class="fa fa-lightbulb-o text-white" aria-hidden="true" style="font-size: 24px"></i>
            </button>
            <v-tooltip attach="ul" activator="parent" location="bottom" role="tooltip" aria-label="Tutorial-Button">
              {{ $t("Tutorial") }}
            </v-tooltip>
          </li>
        </div>

        <li class="onhover-div ">
          <div v-if="language">
            <!-- <NuxtImg loading="lazy" class="flagicon" style="margin: 1px"
              :src="image(`https://unpkg.com/language-icons/icons/${language}.svg`, { height: 18, })" /> -->
            <img :src="imgSource" alt="" class="flagicon" style="margin: 1px">
            <!-- <img loading="lazy" class="flagicon" style="margin: 1px" height="18px" width="18px" alt="Language" -->
            <!-- :src="`https://unpkg.com/language-icons/icons/${language}.svg`" /> -->
          </div>
          <div class="show-div setting">
            <ul class="list-inline">
              <li>
                <img @click="languageSelection('fr')" class="flagicon" style="margin: 1px" height="18px" width="18px"
                  alt="french" src="~~/assets/language/fr.svg" />
              </li>
              <li>
                <img @click="languageSelection('en')" class="flagicon" style="margin: 1px" height="18px" width="18px"
                  alt="english" src="~~/assets/language/uk.svg" />
              </li>
              <li>
                <img @click="languageSelection('de')" class="flagicon" style="margin: 1px" height="18px" width="18px"
                  alt="dutch" src="~~/assets/language/de.svg" />
              </li>
              <li>
                <img @click="languageSelection('nl')" class="flagicon" style="margin: 1px" height="18px" width="18px"
                  alt="nl" src="~~/assets/language/nl.svg" />
              </li>
              <li>
                <img @click="languageSelection('es')" class="flagicon" style="margin: 1px" height="18px" width="18px"
                  alt="spanish" src="~~/assets/language/es.svg" />
              </li>
              <li>
                <img @click="languageSelection('sv')" class="flagicon" style="margin: 1px" height="18px" width="18px"
                  alt="swedish" src="~~/assets/language/sv.svg" />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useCartStore } from "~/store/CartStore";
import { useI18n } from "vue-i18n";
import { useUtilityStore } from "~/store/UtililityStore";
import { useUserStore } from "~/store/UserStore";
import { useOrderStore } from "~/store/OrderStore";
import { useShippingStore } from "~/store/ShippingStore";
import { EventBus } from "~/utils/eventBus.js";
import { useRouter } from 'vue-router';


import { useProductStore } from "~/store/ProductStore";
import gsap from 'gsap'


const route = useRoute();
const router = useRouter();
const cartStore = useCartStore();
const userStore = useUserStore();
const orderStore = useOrderStore();
const shippingStore = useShippingStore();
const productStore = useProductStore();
const utilityStore = useUtilityStore();
const localePath = useLocalePath();
const qtyCart = computed(() => cartStore.qtyCart);
const cart = computed(() => cartStore.cartData);


const { locale } = useI18n();

const accountingSituationLocalStorage = computed(() => orderStore.accountingSituationLocalStorage);
const isShippingCostAdded = computed(() => shippingStore.isShippingCostAdded)
const totalDeliverables = computed(() => parseFloat(orderStore.totalDeliverables))
const isAuthenticated = computed(() => userStore.isAuthenticated);
const cldUser = computed(() => userStore.cldUser);
const Cfranco = computed(() => parseInt(cldUser.value?.Cfranco));

const logout = async () => {
  cartStore.resetCartStates()
  userStore.logout();
  productStore.setProductsBackOrders({})
  router.push({
    path: localePath("/account/login"),
  });
};

const selectedLocale = computed(() => utilityStore.selectedLocale);
const language = ref('');

const tweenedQty = reactive({
  number: qtyCart.value || 0
})
const image = useImage();
onMounted(() => {
  setTimeout(() => {
    language.value = selectedLocale.value || locale.value;
    cartStore.getFromCart();
  }, 0);
});
watch(selectedLocale, () => {
  language.value = selectedLocale.value || locale.value;
})
const languageSelection = (language) => {
  utilityStore.setLanguage(language);
};


const imgSource = computed(() => languageMap[language.value]);


const isAccountingReq = computed(() => {
  const MaxDaysOver = +accountingSituationLocalStorage.value?.MaxDaysOver;
  if (accountingSituationLocalStorage.value?.Color === 'red'
    || MaxDaysOver > 3) {
    return 'red';
  }
  if (accountingSituationLocalStorage.value?.Color === 'orange'
    || (MaxDaysOver > 0 && MaxDaysOver <= 3)) {
    return 'orange';
  }
  else {
    return 'green';
  }

})

watch(
  qtyCart,
  (n) => {
    gsap.to(tweenedQty, { duration: 0.5, number: Number(n) || 0 })
  }
)

const shippingProcessInPercentage = computed(() => {
  if (isAuthenticated.value) {

    if (!totalDeliverables.value) {
      return 0;
    }
    if (isShippingCostAdded.value || totalDeliverables.value >= Cfranco.value) {
      return '100';
    }
    const percentage = (totalDeliverables.value / Cfranco.value) * 100
    return isNaN(percentage) ? 0 : parseInt(percentage);
  }
})

const allowedToursRouteNames = ["products-search"]

const showTourBtn = computed(() => route.name.includes(allowedToursRouteNames))

const emitOpenTour = () => {
  EventBus.emit("open_tour");
}

const setAndRedirect = () => {
  //prevent adding redirect param for home page
  if (route.path === `/${locale.value}/account/login`) {
    return
  }
  if (route.path === `/${locale.value}`) {
    return router.replace(`/${locale.value}/account/login`)
  }
  return router.replace(`/${locale.value}/account/login?redirectTo=${route.fullPath}`)
}

</script>

<style>
.cart-icon {
  text-decoration: none;
  font-size: x-large;
}

.text-acc-name {
  font-size: x-small;
  text-transform: capitalize;
  color: white;
}


.progress {
  width: 70px;

}

.progress-bar-info {
  background-color: #03b778;
}
</style>
