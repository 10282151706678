<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">

  <Body>
    <div>
      <v-app>
        <div id="body-content"></div>
        <StoreKioskHeader />

        <div class="catagory-dropdown ">
          <div class="products-dropdown ">
            <div class="cat-tree ">
              <CategoryCategoryTree />
            </div>

            <div class="catagory-items ">
              <div class="all-items all-items-deals green-nav-height">

                <template v-if="productsMenu && false">
                  <div class="catagory-items-sub-info catagory-item-sub" v-for="pm in productsMenu" :key="pm.key">
                    <p v-if="!privateProductsMenus[pm.key] || (privateProductsMenus[pm.key] && isAuthenticated)"
                      class="catagory-text" @click="addFilter(pm.query)">
                      <img :src="image(pm.img, { height: 25 })" style="width: 25px;" alt="icon" />
                      {{ $t(`menu.${pm.key}`) }} {{ "&nbsp; " }}
                    </p>
                  </div>
                </template>

              </div>
            </div>

          </div>

          <div class="catagory-brands" v-if="brandImages.length">
            <swiper :watchSlidesProgress="true" :slidesPerView="12" :navigation="false" :grabCursor="true" :autoplay="{
              delay: 1500,
              disableOnInteraction: false,
            }" :freeMode="true" :centeredSlides="false" :modules="modules" class="mySwiper swiper">
              <swiper-slide v-for="bImg in brandImages" :key="bImg.slug" class="swiper-slide">
                <NuxtLinkLocale :to="bImg.url" class="catagory-brands-sub-info" aria-label="Image-Link">
                  <img :src="imgUrl(bImg.image.url)" class="img-info-brands" alt="Image" />
                </NuxtLinkLocale>
              </swiper-slide>
            </swiper>
          </div>

        </div>
        <!-- <div  class="placeholder bg-light catagory-dropdown-placeholder">
        </div> -->
   
        <WidgetsBreadcrumbs />
        <div class="container">

          <MiniSearch v-if="!route.name.includes('products-search')" :range="15"></MiniSearch>
        </div>
        <NuxtPage />
        <ClientOnly>
          <div class="tap-top top-cls" v-scroll-to="'#body-content'">
            <div>
              <i class="fa fa-angle-double-up"></i>
            </div>
          </div>
        </ClientOnly>
      </v-app>
    </div>
  </Body>

  </Html>
</template>

<script setup>
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  Navigation,
  Pagination,
  History,
  FreeMode,
  Autoplay,
} from "swiper/modules";
const modules = [Navigation, Pagination, History, FreeMode, Autoplay];
import CategoryCategoryTree from '../components/Category/TreeNavigation.vue';
import { useCategoryStore } from "~/store/CategoryStore";
import { useCmsStore } from "~/store/cms/cmsStore";
import { useI18n } from "vue-i18n";
import { productsMenu, privateProductsMenus } from "~/composables/data";
import { useFilterStore } from "~/store/FilterStore";
import { useWebStore } from "~/store/WebStore";

const route = useRoute();

const { t, locale } = useI18n();
const webStore = useWebStore();
const selectedStore = computed(() => webStore.selectedStore)
const head = useLocaleHead({
  addDirAttribute: true,      // Adds dir
  addSeoAttributes: true,     // Adds lang
})
useHead({
  title: selectedStore.value.title, //" CLD Distribution - Leading European Distributor and Wholesaler in Entertainment and Pop-Culture ",
  meta: [{ name: "description", content: t('cld_desc1'), }],
  link: [...(head.value.link || [])],
});

const htmlAttrs = computed(() => head.value.htmlAttrs)
const image = useImage()
const cmsStore = useCmsStore();
const filterStore = useFilterStore();
const categoryStore = useCategoryStore();
const brandImages = computed(() => cmsStore.brandImages);
const localePath = useLocalePath();
const router = useRouter();
const imgUrl = (url) => cmsStore.getImageUrl(url);
const addFilter = (v) => {
  router.push({ path: localePath('/products/search') })
  setTimeout(() => {
    const { attribute, sort } = v
    filterStore.setSelectedAttributeFilters(attribute)
    if (sort && sort?.length) {
      filterStore.setSelectedSort(sort)
    }
  }, 500);
}
onBeforeMount(() => {
  setTimeout(() => {
    categoryStore.getParentCategories();
    cmsStore.getHomePage();
    cmsStore.getLicense();
    cmsStore.getCoverSliders();
    cmsStore.getCmsCategory();
    cmsStore.getCmsBrand();
    cmsStore.getCmsSlider();

  }, 0);
});

useAsyncData('init_cms_cache', async () => {
  return cmsStore.initFromCache();
}, {
  lazy: true,
  server: false,
});



</script>
<style>
/* CLS */

.catagory-dropdown-placeholder {
  height: 208.6px !important;
  width: 100%;
}
</style>
